import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'


const Banner = () => {
    return (
        <div className="it-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Kreatin Brand Stories</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p> Stories that Connect.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                <Link  className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Whats Yours? <span></span>
                                    </Link>
                                
                                {/** 
                                    <Link to="/contact" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Whats Yours? <span></span>
                                    </Link>
                                    */}
                                </div>
                            </ReactWOW>

                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
