import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Craftsmen Of Stories
                    </span>

                    <h2>We help to deliver your Story</h2>
                    <p>Every story has a purpose</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>Brand Videos</h3>
                            <p>Catapult your brand to new heights with our Brand Videos! We craft cinematic narratives that authentically reflect your Brand.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Digital Ads</h3>
                            <p>Dominate the digital landscape with our Digital Ads! Boost your online presence and drive conversions with video ad campaigns.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>Corporate Videos</h3>
                            <p>Expand your corporate identity with our engaging Videos! Our expert team creates impactful videos to showcase your company's values, and achievements.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Animation</h3>
                            <p>Revolutionize your content with our Animation Videos! Enhance your impact with visually stunning animations tailored to your unique needs.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Product Videos</h3>
                            <p>Transform your product into an emotion! We create compelling visual narratives that present your offerings in the best light.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>TV Commercials</h3>
                            <p>Elevate your brand presence with our TV Commercials offerings! We creatively create captivating high-impact commercials that resonate and connect with your target audience.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures
