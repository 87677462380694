import React from "react"

import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"


import OurFeatures from "../components/Index/OurFeatures"

import Navbar from "../components/App/Navbar"





const Home = () => {
  return (
    <Layout>
      
      <Banner />
           
      <OurFeatures />
          
      <Navbar />      
      
      
      
      <Footer />
    </Layout>
  )
}

export default Home
